import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */

const Logo = () => (
  <svg
    width="782"
    height="88"
    viewBox="0 0 206.90416 23.283334"
    version="1.1"
    id="svg8"
  >
    <defs
      id="defs2"
    />
    <metadata
      id="metadata5"
    />
    <g
      id="layer1"
      transform="translate(0,-273.71665)"
    >
      <g
        transform="scale(0.26458333)"
        id="flowRoot3699"
      >
        <g
          style={{ fill: '#f39c12' }}
          transform="matrix(1.0369334,0,0,1.1021576,-25.455594,1027.1836)"
          id="flowRoot3773"
        >
          <path
            d="m 192.4742,67.402578 c 8.588,0 14.744,-5.776 14.744,-14.44 V 9.6425781 h -11.4 V 51.822578 c 0,2.28 -1.9,4.104 -4.104,4.104 -1.672,0 -3.876,-0.38 -5.32,-0.912 v 11.628 c 1.292,0.456 4.332,0.76 6.08,0.76 z"
            id="path3781"
          />
          <path
            d="m 251.37777,47.566578 c 0,-12.008 -6.764,-19.76 -17.784,-19.76 -12.16,0 -19.228,8.588 -19.228,19.836 0,11.324 6.308,19.76 19.608,19.76 7.6,0 13.604,-3.8 16.416,-9.88 l -7.828,-3.876 c -2.66,3.952 -5.092,5.244 -8.816,5.244 -5.244,0 -7.752,-3.04 -8.36,-8.056 h 25.764 c 0.152,-1.14 0.228,-2.204 0.228,-3.268 z m -17.936,-11.096 c 4.408,0 6.688,2.508 7.448,6.916 h -15.276 c 0.912,-4.18 3.268,-6.916 7.828,-6.916 z"
            id="path3783"
          />
          <path
            d="m 295.3687,28.642578 h -11.02 v 21.964 c 0,3.952 -3.496,7.068 -7.752,7.068 -4.028,0 -7.144,-3.116 -7.144,-7.068 v -21.964 h -11.096 v 23.332 c 0,8.588 6.612,15.428 15.048,15.428 4.256,0 8.056,-1.748 10.944,-4.636 v 3.876 c 0,6.764 -4.104,10.032 -9.348,10.032 -5.092,0 -8.968,-1.444 -12.236,-3.8 v 10.564 c 3.42,1.52 7.296,2.356 12.084,2.356 12.54,0 20.596,-7.6 20.596,-19.152 z"
            id="path3785"
          />
          <path
            d="m 320.4772,67.402578 c 9.272,0 15.2,-4.636 15.2,-12.16 0,-7.448 -6.384,-9.956 -12.084,-11.704 -4.484,-1.444 -8.512,-2.356 -8.512,-4.94 0,-2.204 3.268,-3.04 6.46,-2.66 2.888,0.304 6.688,1.672 8.74,3.192 l 5.168,-7.144 c -4.56,-2.964 -10.792,-4.104 -15.58,-4.104 -9.576,0 -15.884,4.94 -15.884,12.236 0,7.144 6.992,9.272 12.844,10.944 4.256,1.216 7.904,2.28 7.904,4.864 0,2.128 -2.66,3.192 -6.232,2.964 -3.648,-0.228 -8.816,-1.9 -11.324,-4.028 l -4.864,7.828 c 4.18,3.192 11.248,4.712 18.164,4.712 z"
            id="path3787"
          />
          <path
            d="m 377.25277,47.566578 c 0,-12.008 -6.764,-19.76 -17.784,-19.76 -12.16,0 -19.228,8.588 -19.228,19.836 0,11.324 6.308,19.76 19.608,19.76 7.6,0 13.604,-3.8 16.416,-9.88 l -7.828,-3.876 c -2.66,3.952 -5.092,5.244 -8.816,5.244 -5.244,0 -7.752,-3.04 -8.36,-8.056 h 25.764 c 0.152,-1.14 0.228,-2.204 0.228,-3.268 z m -17.936,-11.096 c 4.408,0 6.688,2.508 7.448,6.916 h -15.276 c 0.912,-4.18 3.268,-6.916 7.828,-6.916 z"
            id="path3789"
          />
          <path
            d="m 405.5877,27.882578 c -4.408,0 -8.284,2.584 -10.868,6.612 l -0.608,-5.852 h -9.956 v 38 h 11.096 v -19 c 0,-6.308 4.408,-10.108 8.968,-10.108 3.192,0 4.864,0.988 5.928,1.748 l 4.636,-8.132 c -2.28,-2.204 -4.788,-3.268 -9.196,-3.268 z"
            id="path3791"
          />
          <path
            d="m 462.5497,67.402578 c 4.788,0 9.348,-1.368 13.224,-3.724 v -12.768 c -3.496,3.8 -8.132,5.624 -13.224,5.624 -10.716,0 -16.948,-8.132 -16.948,-18.392 0,-10.26 6.232,-18.316 16.948,-18.316 5.016,0 9.5,1.672 13.224,5.548 v -12.768 c -3.876,-2.432 -8.436,-3.7239999 -13.224,-3.7239999 -16.568,0 -27.816,13.0719999 -27.816,29.2599999 0,16.188 11.248,29.26 27.816,29.26 z"
            id="path3793"
          />
          <path
            d="m 512.4817,41.714578 c 6.916,-1.748 11.856,-7.524 11.856,-15.048 0,-9.5 -7.448,-17.0239999 -16.872,-17.0239999 h -23.56 V 66.642578 h 11.476 v -24.168 h 5.624 l 12.464,24.168 h 12.844 z m -5.472,-8.132 h -11.628 v -13.984 h 11.628 c 3.876,0 6.916,3.04 6.916,6.992 0,3.952 -2.964,6.992 -6.916,6.992 z"
            id="path3795"
          />
          <path
            d="m 580.29508,9.6425781 -18.772,27.7399999 -18.62,-27.7399999 h -12.388 V 66.642578 h 11.476 v -40.888 l 19.456,29.944 19.684,-29.944 v 40.888 h 11.4 V 9.6425781 Z"
            id="path3797"
          />
        </g>
        <flowRoot
          id="flowRoot3800"
        >
          <flowRegion id="flowRegion3802">
            <rect
              id="rect3804"
              width="481.42856"
              height="89.285713"
              x="137.14285"
              y="-3.4285715"
            />
          </flowRegion>
          <flowPara id="flowPara3806" />
        </flowRoot>
      </g>
    </g>
  </svg>
);

Logo.defaultProps = {
  className: '',
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
